import React, { useState, useEffect } from 'react';
 

const reviews = [
  {
    id: 1,
    imgSrc: "review1",
    text: "They have been very punctual & accountable.Very careful & friendly with my Doggo.",
    author: "Subhadeep"
  },
  {
    id: 2,
    imgSrc: "review2",
    text: "Simply the best. I’d recommend barkigo for all my petcare services.",
    author: "Jaya"
  },
  {
    id: 3,
    imgSrc: "review3",
    text: "Its been relief since i have opted barkigo for walking services of my dog.",
    author: "Ritika"
  },
  {
    id: 4,
    imgSrc: "review4",
    text: "Every day walking service has been very help for me personally. Made my life easier.",
    author: "Om Gawde"
  },
  {
    id: 5,
    imgSrc: "review1",
    text: "Recently started using barkigo and now it has been my go to place for my petcare.",
    author: "Sakshi"
  }
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? reviews.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  return (
    <div className="carousel-container">
      {/* <button onClick={handlePrevClick} className="carousel-button prev-button">&#10094;</button> */}
      <div className="carousel-slide">
        <div className="d-flex justify-content-center gap-2">
          <div className="d-flex flex-column flex-lg-row gap-2 px-4 py-x1 review-card">
            <div className="text-center text-lg-start">
              <div className="card-image rounded-circle object-fit-cover" id={reviews[currentIndex].imgSrc}  ></div>
            </div>
            <div className="text-center text-lg-start">
              <p className="fs-8 mb-2 lh-lg line-clamp-3 text-1100">{reviews[currentIndex].text}</p>
              <p className="text-800 lh-xl mb-0">{reviews[currentIndex].author}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <button onClick={handleNextClick} className="carousel-arrow carousel-arrow--prev" >&#10095;</button> */}
    </div>
  );
};

export default Carousel;
