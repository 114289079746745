// import logo from './logo.svg';
// import barkigo from './images/barkigo-logo.png'
import './App.css';
import React, { useEffect,useRef,useState } from 'react';
// import Swiper from 'swiper';
//  import Navbar from './components/Nav'
 import Carousel from './components/Carousal';
import Clean from './images/pawcleaning.mp4'
import $ from 'jquery';
//  import { CssBaseline, ThemeProvider } from "@mui/material";
 import { ColorModeContext, useMode } from "./theme";
import ReactPlayer from "react-player"
//  import Isotope from 'isotope-layout';


//  import lottie from 'lottie-web';
// import animationData from './images/top.json'; // Replace with the correct path to your animation file
// import Select from 'react-select';
// import Alert from '@mui/material/Alert';
// import axios from 'axios';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// const options = [
//   { value: 'password1', label: 'Password Option 1' },
//   { value: 'password2', label: 'Password Option 2' },
//   { value: 'password3', label: 'Password Option 3' },
//   // Add more options as needed
// ];


// const LottieAnimation = ({ animationData, loop = true, autoplay = true }) => {
//   const animationContainer = useRef(null);

//   useEffect(() => {
    
//     let anim = null;
//     if (animationContainer.current) {
//       anim = lottie.loadAnimation({
//         container: animationContainer.current,
//         renderer: 'svg',
//         loop: loop,
//         autoplay: autoplay,
//         animationData: animationData,
//       });
//     }

//     return () => {
//       if (anim) {
//         anim.destroy();
//       }
//     };
//   }, [animationData, loop, autoplay]);

//   return <div ref={animationContainer} />;
// };
function App() {
  const currentMonth = new Date().getMonth(); 
  const specificMonth = 7;
  // console.log(currentMonth)
  const [selectedOption, setSelectedOption] = useState(null);

  // const [theme, colorMode] = useMode();
  // const eventBoxRef = useRef(null);
  // const filtersRef = useRef(null);
  // let iso = null;

  // const [labelVisible, setLabelVisible] = useState(true);


  let [count, setCount] = useState(1)
  // function handleNextField() {
  //   const inputs = Array.from(document.querySelectorAll('.header__search-form input, .header__search-form select'));
  //   const activeElement = document.activeElement;
  //   // const currentIndex = inputs.indexOf(activeElement);
  //    const signbutton=document.getElementsByClassName('signup-button')
  //   const currentIndex = count;
  //   console.log('#input-'+currentIndex)
  //   document.querySelector('#input-'+currentIndex).style.opacity=0;
  //   document.querySelector('[for="input-'+currentIndex+'"]').style.opacity=0;
  //   setCount(prevCount => prevCount + 1);
  //   console.log(count)
  //   const nextIndex = (currentIndex + 1) % inputs.length;
  //   // inputs[currentIndex].opacity=1;
  //   console.log(document.querySelectorAll('#input-2'))
  //   document.querySelector('#input-'+nextIndex).style.opacity=1;
  //   document.querySelector('#input-'+nextIndex).focus();
  // }
  // const handleSignUpClick = () => {
  //   const signupButton = document.querySelector('.signup-button');
  //   if (signupButton) {
  //     signupButton.style.visibility = 'hidden';
  //   }
  //   const firstinp = document.querySelector('#input-1');
  //   console.log(firstinp)
  //   if (firstinp) {
       
  //     firstinp.style.opacity=1;
  //   }
  //   document.querySelector('[for="input-1"]').style.opacity=1;
  // };
  // const handlePasswordChange = (selectedOption) => {
  //   setSelectedOption(selectedOption);
  // };

  // const scriptURL='https://script.google.com/macros/s/AKfycbzOZDqSedSQBHC3uUCzJ9NlxMxa0lvYGEoeAwqj2s1jL-LNNxSaO90pgXINyPV8lxE2uw/exec'
  
  
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const form = e.target;

  //   // Create a JSON object from the form data
  //   const formData = new FormData(form);
  //   const data = {};
  //   formData.forEach((value, key) => {
  //     data[key] = value;
  //   });
  //   console.log(data)
  //   try {
  //     const response = await axios.post(scriptURL, data, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       }
  //     });
  //     console.log(response)
  //     if (response.result === 'success') {
  //       alert('Thank you! Booking Successfull.');
  //       window.location.href = '/';
  //     } else {
  //       alert('Something went wrong. Please try from Whatsapp.');
  //     }
  //   } catch (error) {
  //     console.error('Error!', error.message);
  //     alert('Please try again later, or use Whatsapp for booking.');
  //   }
  // };
  const [menuActive, setMenuActive] = useState(false);

  // useEffect(()=>{
  //   const searchWrap = $('.header__search');
  //   const searchField = searchWrap.find('.search-field');
  //   const closeSearch = searchWrap.find('.header__search-close');
  //   const searchTrigger = $('.header__search-trigger');
  //   const siteBody = $('body');

  //   searchTrigger.on('click', (e) => {
  //     setLabelVisible(true);

  //     e.preventDefault();
  //     e.stopPropagation();
  //     siteBody.addClass('search-is-visible');
  //     setTimeout(() => {
  //       searchField.focus();
  //     }, 100);
  //   });

  //   closeSearch.on('click', (e) => {
  //     console.log("clicked x and terminated")
  //     e.stopPropagation();
  //     siteBody.removeClass('search-is-visible');
  //     setTimeout(() => {
  //       searchField.blur();
  //     }, 100);
  //   });

  //   // searchWrap.on('click', (e) => {
  //   //   console.log("terminated from wrap")
  //   //   if (!$(e.target).is('.search-field')) {
  //   //     closeSearch.trigger('click');
  //   //   }
  //   // });

  //   searchField.on('click', (e) => {
  //     e.stopPropagation();
  //   });

  //   searchField.attr({ placeholder: 'Type Keywords', autocomplete: 'off' });
  // },[]);

  const playerRef = useRef(null);
 
  const handlePlay = () => {
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().play();
    }
  };

  useEffect(() => {
    // This code runs when the component is mounted
    const preloader = document.getElementById('js-preloader');
    if (preloader) {
      preloader.classList.add('loaded');
    }
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPos = window.scrollY;
      document.querySelectorAll('.nav a').forEach((currLink) => {
         
        const refElement = document.querySelector(currLink.getAttribute('href'));
        
        
         
        if (refElement)  {
          if (refElement.offsetTop <= scrollPos && (refElement.offsetTop + refElement.offsetHeight) > scrollPos) {
            document.querySelectorAll('.nav ul li a').forEach((link) => link.classList.remove('active'));
            currLink.classList.add('active');
          } else {
            currLink.classList.remove('active');
          }
        }
      });
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setMenuActive(!menuActive);
  };
  const [currentFocus, setCurrentFocus] = useState(null);

   function book_now(){
    window.location.href = "https://api.whatsapp.com/send?phone=918618498221&text=Hi%20*Barkigo*,I'm%20interested%20in%20your%20services";
   }

  const keepFocus = (event) => {
    // if (currentFocus && !currentFocus.contains(event.target)) {
    //   currentFocus.focus();
    // }
  };

  useEffect(() => {
    document.addEventListener('click', keepFocus);
    return () => {
      document.removeEventListener('click', keepFocus);
    };
  }, [currentFocus]);

  // const handleFocus = (event) => {
  //   setCurrentFocus(event.target);
  // };
  // const handleLinkClick = (e) => {
  //   e.preventDefault();
  //   const target = document.querySelector(e.currentTarget.getAttribute('href'));
  //   if (target) {
  //     const width = window.innerWidth;
  //     if (width < 767) {
  //       setMenuActive(false);
  //     }
  //     window.scrollTo({
  //       top: target.offsetTop - 80,
  //       behavior: 'smooth',
  //     });
  //   }
  // };
  return ( //<ColorModeContext.Provider value={colorMode}>   
  //   <ThemeProvider theme={theme}>
  //     <CssBaseline/>
  
    <div className="App">
       
       <div id="js-preloader" className="js-preloader">
    <div className="preloader-inner">
      <span className="dot"></span>
      <div className="dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
     
    <h3>BARKIGO</h3>
  </div>

 <a href="https://api.whatsapp.com/send?phone=918618498221&text=Hi%20Barkigo,I'm%20interested%20in%20your%20services"   className="float" target="_blank">
<i className="fa fa-whatsapp my-float"></i>
</a>
 
<div className="header__search">

{/* <form role="search" method="get" className="header__search-form" action="#">
    <label>
        <span className="hide-content">Search for:</span>
            </label><input type="search" className="header__search-field" placeholder="Type Keywords" value="" name="s" title="Search for:" autocomplete="off"/>
 
    <input type="submit" className="header__search-submit" value="Search"/>
</form> */}
{/* <form  className="header__search-form"  method='post' onSubmit={handleSubmit} >
    <input className='inp' id="input-1" type="text" placeholder="John Doe" required    name="Owner"/>
  <label for="input-1">
    <span className="label-text">Pet Owner NAME</span>
    <span className="nav-dot"></span>
     
    <div className="signup-button-trigger"  onClick={handleSignUpClick}>Sign Up</div>
  </label>
  <select 
        id="input-2"
        required
        onFocus={handleFocus}
        className="styled-select"
        name='Service'
      >
        <option value="" disabled selected>
          What service you need?
        </option>
        <option value="Walking">Walking</option>
        <option value="Birthday">Birthday</option>
        <option value="Grooming">Grooming</option>
        <option value="Breeding">Breeding</option>
        <option value="Boarding">Boarding</option>
        <option value="Training">Training</option>
      </select>
  <label for="input-2">
    <span className="label-text">Services</span>
    <span className="nav-dot"></span>
  </label>
  <input  className='inp' id="input-3" type="tel" placeholder="Whatsapp number" onFocus={handleFocus}  name='MobileNo'required  />
  <label for="input-3">
    <span className="label-text">MOBILE NUMBER</span>
    <span className="nav-dot"></span>
  </label>
  <select
        id="input-4"
        required
        onFocus={handleFocus}
        className="styled-select"
        name='Area' 
      >
        <option value="" disabled selected>
          Your location?
        </option>
        <option value="Kormangala">Kormangala</option>
        <option value="Adugudi">Adugudi</option>
        <option value="Krpuram">Krpuram</option>
        <option value="Hsr">Hsr</option>

      </select>
  <label for="input-4">
    <span className="label-text">LOCALITY</span>
    <span className="nav-dot"></span>
  </label>
  
  <input  className='inp'id="input-5" type="datetime-local"  required  onFocus={handleFocus} name="Slot"/>
  <label for="input-5">
    <span className="label-text">Select Booking slot</span>
    <span className="nav-dot"></span>
  </label>
  <button type="submit" id="input-0" onFocus={handleFocus}>Confirm ✔</button>
  <p className="tip">Press Tab</p>
  <div type="button" className="nexttip" id="next-button" onClick={handleNextField}>Next</div> */}

  {/* <button type="button"  className='next' onClick={handleNextField}>Next</button> */}
   
        {/* <label htmlFor="signup-button" className="label-above-signup" >
          We've heard that you want to book for PetPAl from <span style={{ fontWeight: 'bold' }}>BARKIGO.</span>
        </label>
     
    <div className="signup-button" >I sure do</div>
  
</form> */}
<a href="#0" title="Close Search" className="header__search-close">Close</a>

</div>   

  <header className="header-area header-sticky background-header">
    <div className="container">
     
        <div className="row">
            <div className="col-12">
                <nav className="main-nav">
                  <div id="logo"></div>
                  {/* <div id="logo1"></div> */}
                    {/* <a href="index.html" className="logo">
                        <h1>BARKIGO</h1>
                    </a> */}
                    <a href="index.html" className="barkigo"></a>
                    <div className="search-input">
                      <form id="search" action="#">
                        <input type="text" placeholder="Search" id='searchText' name="searchKeyword"   />
                        <i className="fa fa-search"></i>
                      </form>
                    </div>
                    {/* style={{display: 'flex'}} */}
                     
                 
                      {/* <a className="smallnav header__search-trigger"  >Book Now!</a>  */}
                      <a className="smallnav"  href="#contact" id="booknow"  onClick={book_now}>Book Now!</a> 
                      
                      
 
                    <ul className="nav" > 
                      <li className="scroll-to-section"><a href="#top" className="active">Home</a></li>
                      <li className="scroll-to-section"><a href="#services">Services</a></li>
                      <li className="scroll-to-section"><a href="#courses">Gallery</a></li>
                      <li className="scroll-to-section"><a href="#socials">Socials</a></li>
                      {/* <li className="scroll-to-section"><a href="#events">Events</a></li> */}
                      <li className="scroll-to-section" id="booknow"><a href="#contact"  className=" " style={{backgroundColor:"rgb(28, 30, 33)", color:"#ffde59" ,borderRadius:"1.3rem",border:"1px solid #ffde59"}} onClick={book_now}>Book Now!</a></li>
                  </ul>   
                    <a className='menu-trigger'>
                        <span>Menu</span>
                    </a>
          
                </nav>
                
            </div>
        </div>
    </div>
  </header>
 

  <div className="main-banner" id="top">
    <div className="container" id="anim"> 
    {/* <LottieAnimation animationData={animationData} />
  */}
  <button>BOOK NOW!</button>
  
    </div>
  </div>
  {currentMonth==specificMonth &&(
  <div className="independence-banner"></div>)}
  <div className="services section" id="services">
  <div className="container">
 
        <div className="col-lg-12 text-center">
          <div className="section-heading">
            <h6 style={{color:"black"}}><span style={{fontSize:"20px" ,fontWeight:"bold"}}>"</span>The love for all living creatures is the most noble attribute of man.<span style={{fontSize:"20px" ,fontWeight:"bold"}}>"</span> <span style={{fontWeight:"lighter" ,fontSize:"12px"  ,color:"#ffde59" }}>-Charles Darwin</span></h6>
            <h2>Our Services</h2>
          </div>
        </div>
      </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
            <div className="icon" id="walk">
              
            </div>
            <div className="main-content">
              <h4>Walking</h4>
              <p>Walking service for your pet now available in your location.  Book a petpal now !</p>
              <div className="main-button">
                <a href="https://api.whatsapp.com/send?phone=918618498221&text=Hi%20Barkigo,I need booking for Walking service!">Book Now</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
            <div className="icon" id="grooming">
                
            </div>
            <div className="main-content">
            <h4>Training</h4>
            <p>Unlock your pet's full potential with our professional training services. Book a petpal now !</p>
              <div className="main-button">
                <a href="#">Coming soon</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
          <div className="icon" id="training">
            </div>
            <div className="main-content">
              <h4>Grooming</h4>
              <p>From Bathing to styling ,we ensure your furry friend looks and feels their best!</p>
              <div className="main-button">
                <a href="https://api.whatsapp.com/send?phone=918618498221&text=Hi%20Barkigo,I need booking for Grooming!">Book Now</a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="service-item">
          <div className="icon" id="breeding">
            </div>
            <div className="main-content">
              <h4>Breeding</h4>
              <p>Find the perfect match for your pet through our trusted breeding portal.And ensure a bright future .</p>
              <div className="main-button">
                <a href="#">Coming soon</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
          <div className="icon" id="birthday">
            </div>
            <div className="main-content">
              {/* <span style={{fontStyle:"bold",color:"orange",fontSize:"20px"}}>COMING SOON!</span> */}
              <h4>Birthdays</h4>
              <p>Celebrate your dog's special day with our exclusive birthday celebrations. From custom cakes to fun-filled parties.</p>
              <div className="main-button">
                <a href="https://api.whatsapp.com/send?phone=918618498221&text=Hi%20Barkigo,I need booking for Birthday Celebration of my Doggo!">Let's paw-ty</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="service-item">
          <div className="icon" id="daycare">
            </div>
            <div className="main-content">
              <h4>Daycare</h4>
              <p>Ensure your pet feels at home while you're away with our top-notch daycare services. Make them feel safe & comfortable !</p>
              <div className="main-button">
                <a href="#">Coming soon</a>
              </div>
            </div>
          </div>
        </div>
  
    </div>
    
  </div>
  </div>
  
  <div className="mid-banner">
       
 
       </div>
  
  <section className="section courses" id="courses" >
    <div className="container">
      <div className="row">
        
        <div className="col-lg-12 text-center">
          <div className="section-heading">
          <h6 style={{color:"black"}}><span style={{fontSize:"20px" ,fontWeight:"bold"}}>"</span>The greatness of a nation can be judged by the way its animals are treated.<span style={{fontSize:"20px" ,fontWeight:"bold"}}>"</span> <span style={{fontWeight:"lighter" ,fontSize:"12px",color:"#ffde59"}}>-Mahatma Gandhi</span></h6>
            <h2>Gallery</h2>
          </div> 
        </div>
      </div> 
      <ul className="event_filter"  >
        <li>
          <a className="is_active" href="#!" data-filter="*">Show All</a>
        </li>
        <li>
          <a href="#!" data-filter="Walking">Walking</a>
        </li>
        <li>
          <a href="#!" data-filter=".Daycare">Daycare</a>
        </li>
        <li>
          <a href="#!" data-filter=".wordpress">Grooming</a>
        </li>
      </ul>
       
 

      <div className="row event_box"  >
        <div className="col-lg-4 col-md-6 align-self-center mb-30 event_outer col-md-6 design">
          <div className="events_item">
            <div className="thumb">
              <div id="gallery1"> </div> 

              {/* <span className="category">Walking</span> */}
              {/* <span className="price"><h6><em>$</em>160</h6></span> */}
            </div>
            <div className="down-content">
              <span className="author">Mylo</span>
              <h4>Walking</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 align-self-center mb-30 event_outer col-md-6  development">
          <div className="events_item">
            <div className="thumb">
              <div id="gallery2"></div>
              {/* <span className="category">Grooming</span> */}
              {/* <span className="price"><h6><em>$</em>340</h6></span> */}
            </div>
            <div className="down-content">
              <span className="author">Tommy</span>
              <h4>Grooming</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 align-self-center mb-30 event_outer col-md-6 design wordpress">
          <div className="events_item">
            <div className="thumb">
            <div id="gallery3"> </div>
              {/* <span className="category">Birthday</span> */}
              {/* <span className="price"><h6><em>$</em>640</h6></span> */}
            </div>
            <div className="down-content">
              <span className="author">Blacky</span>
              <h4>Birthday bash</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 align-self-center mb-30 event_outer col-md-6 development">
          <div className="events_item">
            <div className="thumb">
            <div id="gallery4"> </div>
              {/* <span className="category">Daycare</span> */}
              {/* <span className="price"><h6><em>$</em>450</h6></span> */}
            </div>
            <div className="down-content">
              <span className="author">Baggle</span>
              <h4>Day care</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 align-self-center mb-30 event_outer col-md-6 wordpress development">
          <div className="events_item">
            <div className="thumb">
            <div id="gallery5"> </div>
              {/* <span className="category">Walking</span> */}
              {/* <span className="price"><h6><em>$</em>320</h6></span> */}
            </div>
            <div className="down-content">
            <span className="author">Mylo</span>
              <h4>Paw Cleaning</h4>
            </div>
          </div>
        </div>
         
        <div className="col-lg-4 col-md-6 align-self-center mb-30 event_outer col-md-6 wordpress design">
          <div className="events_item">
            <div className="thumb">
            <div id="gallery6"> </div>
              {/* <span className="category">Birthday</span> */}
              {/* <span className="price"><h6><em>$</em>240</h6></span> */}
            </div>
            <div className="down-content">
              <span className="author">Tom</span>
              <h4>Birthday</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
   
  
  <section className="experience position-relative overflow-hidden" id="service">
  
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12"  >
                  <div className="position-relative z-1 text-center mb-8 mb-lg-9" data-video-player-container="data-video-player-container">
                            <div id="vidcontainer" onClick={handlePlay} style={{ position: 'relative',marginBottom:"13%", borderRadius: '2rem' ,marginTop:'300px'}}  onContextMenu={(e) => e.preventDefault()} >
     
                  <ReactPlayer id="vid" ref={playerRef} url={Clean} loop={true}  playing={true} controls={false} width={300} height={720} />
                 
            
                  {/* "https://player.vimeo.com/video/972864770?h=072ed846d1&autoplay=1&loop=1&background=1" */} 
      {/* <iframe
        title="Vimeo Video"   
        src="https://player.vimeo.com/video/972864770?h=072ed846d1&autoplay=1&loop=1&background=1"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%' , borderRadius: '1rem'}}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        id='vid'
        
      ></iframe> */}
 
  </div> 
                    <div className="position-absolute dots d-none d-sm-block" id="dots"></div>
                  </div>
                  <h3 className="fs-6 fs-lg-5 fw-bold text-white text-center pt-7 pb-4 pt-lg-9 pb-lg-6 lh-lg" style={{marginTop:"-10px"}}> <span className="underline"> Complimentary Post-walk</span> waste and paw cleaning.</h3>
                
                  <br></br> <br></br> 
                </div>
                
                <div className="col-12">
                  <div className="row gy-4 g-md-3 pb-8 pb-lg-11 px-1">
                    
                    <br></br> <br></br> <br></br><br></br> <br></br> <br></br>
                     </div>
                </div>
              </div>
            </div>
            <div className="position-absolute top-0 start-0 end-0">
              <div className="bg-white py-3 py-md-9 py-xl-10"> </div><div className="wave"></div>
            </div>
          </section>

          
          <section className="bg-1100 mt-n1">
            <div className="mx-auto text-center">
               
            </div>
            <div className="container pb-8 pb-lg-10">
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-5">
                <div className='container' style={{marginLeft:"130px"}} id="dog-container" >
        <div className="dog">
  <div className="dog-body">
    <div className="dog-tail">
      <div className="dog-tail">
        <div className="dog-tail">
          <div className="dog-tail">
            <div className="dog-tail">
              <div className="dog-tail">
                <div className="dog-tail">
                  <div className="dog-tail"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="dog-torso"></div>
  <div className="dog-head">
    <div className="dog-ears">
      <div className="dog-ear"></div>
      <div className="dog-ear"></div>
    </div>
    <div className="dog-eyes">
      <div className="dog-eye"></div>
      <div className="dog-eye"></div>
    </div>
    <div className="dog-muzzle">
      <div className="dog-tongue"></div>
    </div>
  </div>
</div> 

<div className="ball" tabIndex="0"></div>
</div>
                  <h2 className="fs-6 fs-lg-5 fw-bold text-white text-center pt-7 pb-4 pt-lg-9 pb-lg-6 lh-lg" style={{marginTop:"16px"}}> <span className="underline"> Our </span> customer shared their experience on our site!</h2>
                </div>
             
                <div className="col-12 mb-4 mb-lg-6">
                  
                  <div className="row g-2">
                    <div className="swiper-theme-container">
                       <div className="swiper position-relative" data-swiper='{"spaceBetween":20,"mousewheel":{"forceToAxis":true,"sensitiviye":20,"releaseOnEdges":true},"autoplay":{"delay":3000,"disableOnInteraction":false},"breakpoints":{"0":{"slidesPerView":1,"direction":"horizontal","centeredSlide":true,"loop":true},"768":{"direction":"horizontal","centeredSlide":true,"slidesPerView":2,"loop":true},"992":{"slidesPerView":3,"direction":"vertical","loop":false}}}'>
                        <div className="swiper-wrapper">
                          <div className="col-12 swiper-slide">
                                <Carousel />
                            </div>

                          </div>
                          </div>
                           {/*<div className="col-12 swiper-slide">
                            <div className="d-flex justify-content-center gap-2">
                              <div className="d-flex flex-column flex-lg-row gap-2 px-4 py-x1 review-card-2">
                                <div className="text-center text-lg-start"><img className="card-image rounded-circle object-fit-cover" src="assets/img/feedback/reviewer_2.webp" alt="" /></div>
                                <div className="text-center text-lg-start">
                                  <p className="fs-8 mb-2 lh-lg line-clamp-3 text-1100">“Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users who want success.”</p>
                                  <p className="text-800 lh-xl mb-0">Barry Young</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 swiper-slide">
                            <div className="d-flex justify-content-center gap-2">
                              <div className="d-flex flex-column flex-lg-row gap-2 px-4 py-x1 review-card-3">
                                <div className="text-center text-lg-start"><img className="card-image rounded-circle object-fit-cover" src="assets/img/feedback/reviewer_3.webp" alt="" /></div>
                                <div className="text-center text-lg-start">
                                  <p className="fs-8 mb-2 lh-lg line-clamp-3 text-1100">“Must have book for all, who want to be Product Designer or Interaction Designer.”</p>
                                  <p className="text-800 lh-xl mb-0">Esther Allison</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 swiper-slide">
                            <div className="d-flex justify-content-center gap-2">
                              <div className="d-flex flex-column flex-lg-row gap-2 px-4 py-x1 review-card-4">
                                <div className="text-center text-lg-start"><img className="card-image rounded-circle object-fit-cover" src="assets/img/feedback/kakashi.webp" alt="" /></div>
                                <div className="text-center text-lg-start">
                                  <p className="fs-8 mb-2 lh-lg line-clamp-3 text-1100">“Every day, I eat, sleep, and worry about stupid things. My job is to ensure that these kids have as many days as possible where they can be idiots like this.”</p>
                                  <p className="text-800 lh-xl mb-0">Kakashi Hatake</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 swiper-slide">
                            <div className="d-flex justify-content-center gap-2">
                              <div className="d-flex flex-column flex-lg-row gap-2 px-4 py-x1 review-card-5">
                                <div className="text-center text-lg-start"><img className="card-image rounded-circle object-fit-cover" src="assets/img/feedback/obito.webp" alt="" /></div>
                                <div className="text-center text-lg-start">
                                  <p className="fs-8 mb-2 lh-lg line-clamp-3 text-1100">“Life isn’t permanent or predictable. You never know when you will run out of time. The best thing you can do is to be honest with those around you and let them know how you really feel. You never know if you have a tomorrow to come.”</p>
                                  <p className="text-800 lh-xl mb-0">Obito Uchiha</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    
                    </div>
                  </div> <div className="text-center"><a href="https://forms.gle/HqhWczZXtBLCAgXQ8" style={{backgroundColor:"rgb(28, 30, 33)", color:"White" ,borderRadius:"1.3rem",padding:"20px"}}>Submit yout Review!</a></div>
                </div>
 
              </div>
            </div>
          </section>
  

          <footer id="socials">
    <div className="container">
    <div id="bottom-logo"  style={{marginTop:"10px"}}></div>
    {/* <h3 className="fs-6 fs-lg-5 fw-bold text-white text-center pt-7 pb-4 pt-lg-9 pb-lg-6 lh-lg" style={{marginTop:40,color:'#ffde59',  alignItems: "center"}}> <span className="underline"> BARKIGO</span>.com</h3> */}
    <div className="social-menu">
        <ul>
            <li><a href="https://api.whatsapp.com/send?phone=918618498221&text=Hi,%20Barkigo!" target="blank"><i className="fab fa-whatsapp"></i></a></li>
            <li><a href="https://www.instagram.com/barkigo.care/" target="blank"><i className="fab fa-instagram"></i></a></li>
            <li><a href="https://www.linkedin.com/in/rayid-ahmed-89042014b/" target="blank"><i className="fab fa-linkedin"></i></a></li>
            {/* <li><a href="https://codepen.io/sanketbodke"><i className="fab fa-codepen" target="blank"></i></a></li> */}
        </ul>
    </div>
      <div className="col-lg-12" style={{marginTop:"40px"}} id="copyright">
        <p>Copyright © 2024 Barkigo. All rights reserved.&nbsp;Designed by : <a href="https://www.linkedin.com/in/rayid-ahmed-89042014b/" rel="nofollow" target="_blank">Rayid </a>  </p>
      </div>
    </div>
    
  </footer>
   
</div>
// </ThemeProvider>
// </ColorModeContext.Provider>

  );
}

export default App;
